import { gql, useQuery } from '@apollo/client'

interface CurrentUser {
  active?: boolean
  name: string
  url: string
  avatarUrl: string
  organization: {
    name: string
    urlKey: string
    logoUrl: string
  }
}

interface QueryData {
  viewer: CurrentUser
}

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    viewer {
      active
      name
      organization {
        name
        urlKey
        logoUrl
      }
      url
      avatarUrl
    }
  }
`

export const useUser = () => {
  const { loading, error, data } = useQuery<QueryData>(GET_CURRENT_USER)
  return { loading, error, currentUser: data?.viewer || {} }
}
