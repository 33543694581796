import 'core-js/stable'
import React from 'react'
import { render } from 'react-dom'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { sendToVercelAnalytics } from './vitals'
import { BrowserRouter } from 'react-router-dom'
import '@elastic/charts/dist/theme_only_dark.css'

const container = document.getElementById('root')
render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  container
)

reportWebVitals(sendToVercelAnalytics)
