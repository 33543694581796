import React from 'react'
import styled from '@emotion/styled'
import { EuiCard, EuiButton } from '@elastic/eui'
import { useOAuth } from '../../../features/authentication/useOAuth'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  width: 20vw;
`

export const LoginRootPage = () => {
  const { authenticate } = useOAuth()
  return (
    <Wrapper>
      <Content>
        <EuiCard
          textAlign="center"
          title="Login to Linear"
          description={<span>Login to your Linear account to get started</span>}
        >
          <EuiButton onClick={authenticate}>Authenticate with Linear</EuiButton>
        </EuiCard>
      </Content>
    </Wrapper>
  )
}
