import React, { useMemo } from 'react'
import { Axis, Chart, LineSeries, Position, ScaleType, Settings } from '@elastic/charts'
import { EUI_CHARTS_THEME_DARK } from '@elastic/eui/dist/eui_charts_theme'
import { addDays, differenceInDays, format } from 'date-fns'

export const CycleBurdown = ({ cycle }) => {
  const { startsAt, endsAt, completedScopeHistory } = cycle
  const chartData = useMemo(() => {
    const startDate = new Date(startsAt)
    const endDate = new Date(endsAt)
    const dayCount = differenceInDays(endDate, startDate)
    console.log(startDate)
    console.log(endDate)
    console.log(dayCount)
    const chartData = []
    for (let i = 0; i < dayCount; i++) {
      chartData.push([addDays(startDate, i).getTime(), completedScopeHistory[i]])
    }

    // const chartData = completedScopeHistory.map((v, i) => [addDays(startDate, i).getTime(), v + i])
    console.log(chartData)
    return chartData
  }, [startsAt, endsAt, completedScopeHistory])
  return (
    <Chart size={{ height: 200 }}>
      <Settings theme={EUI_CHARTS_THEME_DARK} />
      <Axis id="bottom" position={Position.Bottom} showOverlappingTicks tickFormat={tick => format(tick, 'PP')} />
      <Axis id="left" title="fjdsfjdsh" position={Position.Left} tickFormat={d => `${Number(d).toFixed(2)}%`} />
      <LineSeries
        id={'lines1'}
        xScaleType={ScaleType.Ordinal}
        yScaleType={ScaleType.Linear}
        xAccessor={0}
        yAccessors={[1]}
        data={chartData}
      />
    </Chart>
  )
}
