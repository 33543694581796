import { useId } from 'react'
import { revokeToken, startAuthRedirect } from './api'
import { useStoredState } from './localStorage'

export const useOAuth = () => {
  const state = useId()
  useStoredState(state)

  return {
    authenticate: () => startAuthRedirect(state),
    logout: () => revokeToken()
  }
}
