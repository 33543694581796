import React, { useEffect, useState } from 'react'
import { useStoredToken } from '../authentication'
import { linearClient } from './client'
import { ApolloProvider } from '@apollo/client'

const LinearContext = React.createContext({
  currentTeam: {},
  currentCycle: {},
  setCurrentTeam: () => {},
  setCurrentCycle: () => {}
})

export const LinearProvider = ({ children }) => {
  const [isClientReady, setClientReadiness] = useState(false)
  const { storedToken } = useStoredToken()
  const [currentTeam, setCurrentTeam] = useState({})
  const [currentCycle, setCurrentCycle] = useState({})

  useEffect(() => {
    if (storedToken) {
      setClientReadiness(true)
    }
  }, [storedToken])

  if (!isClientReady || !storedToken) {
    return <div>waiting</div>
  }

  return (
    <ApolloProvider client={linearClient}>
      <LinearContext.Provider
        value={{
          currentTeam,
          currentCycle,
          setCurrentTeam,
          setCurrentCycle
        }}
      >
        {children}
      </LinearContext.Provider>
    </ApolloProvider>
  )
}
