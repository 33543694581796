import {
  CLIENT_ID,
  CLIENT_SECRET,
  LINEAR_AUTH_URL,
  LINEAR_REVOKE_TOKEN_URL,
  LINEAR_TOKEN_URL,
  REDIRECT_URI
} from './constants'
import { getStoredAccessToken } from './localStorage'

export const startAuthRedirect = state => {
  const params = new URLSearchParams()
  params.append('client_id', CLIENT_ID)
  params.append('redirect_uri', REDIRECT_URI)
  params.append('response_type', 'code')
  params.append('scope', 'read')
  params.append('prompt', 'consent')
  params.append('actor', 'user')
  params.append('state', state)
  window.location.assign(`${LINEAR_AUTH_URL}?${params.toString()}`)
}

export const exchangeToken = async code => {
  const data = new URLSearchParams()
  data.append('code', code)
  data.append('client_id', CLIENT_ID)
  data.append('redirect_uri', REDIRECT_URI)
  data.append('client_secret', CLIENT_SECRET)
  data.append('grant_type', 'authorization_code')
  const response = await fetch(LINEAR_TOKEN_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    body: data
  })
  return await response.json()
}

export const revokeToken = async () => {
  const token = getStoredAccessToken()
  await fetch(LINEAR_REVOKE_TOKEN_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
