import React, { useState } from 'react'
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiHeaderBreadcrumbs,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiPopover,
  EuiSpacer,
  EuiText,
  useGeneratedHtmlId
} from '@elastic/eui'
import { useTeamActiveCycle, useUser, useUserTeam } from '../../../features/linear'
import { useStoredToken } from '../../../features/authentication'
import { getFormattedTimeLeft } from '../../../features/authentication/expiryTime'
import { useParams } from 'react-router-dom'

/**
 * This demo has been simplified to showcase just the buttons within sections.
 * See the main example for all the menu items.
 */

export const Header = () => {
  const { currentUser } = useUser()
  const { teamKey } = useParams()
  const { team } = useUserTeam(teamKey)
  const { cycle } = useTeamActiveCycle(teamKey)
  const breadcrumbs = [
    {
      text: currentUser?.organization?.name
    },
    {
      text: team?.name,
      color: team?.color
    },
    {
      text: `Cycle ${cycle?.number}`
    }
  ]
  return (
    <EuiHeader>
      <EuiHeaderSection grow={false}>
        <EuiHeaderSectionItem>
          <EuiAvatar name="currentUser?.organization?.name" size="m" imageUrl={currentUser?.organization?.logoUrl} />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
      <EuiHeaderBreadcrumbs breadcrumbs={breadcrumbs} />
      <EuiHeaderSection side="right">
        <EuiHeaderSectionItem>
          <HeaderUserMenu />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  )
}

const HeaderUserMenu = () => {
  const { currentUser } = useUser()
  const { storedExpiresIn } = useStoredToken()
  const headerUserPopoverId = useGeneratedHtmlId({
    prefix: 'headerUserPopover'
  })
  const [isOpen, setIsOpen] = useState(false)
  const onMenuButtonClick = () => setIsOpen(!isOpen)
  const closeMenu = () => setIsOpen(false)

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={headerUserPopoverId}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Menu"
      onClick={onMenuButtonClick}
    >
      <EuiAvatar name={currentUser?.name || ''} size="s" imageUrl={currentUser?.avatarUrl} />
    </EuiHeaderSectionItemButton>
  )

  return (
    <EuiPopover
      id={headerUserPopoverId}
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={closeMenu}
      panelPaddingSize="none"
    >
      <div style={{ width: 320 }}>
        <EuiFlexGroup gutterSize="m" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiAvatar name={currentUser?.name || ''} size="xl" />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>{currentUser?.name}</p>
              <p>Session validity: {getFormattedTimeLeft(storedExpiresIn)}</p>
            </EuiText>

            <EuiSpacer size="m" />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent="spaceBetween">
                  <EuiFlexItem grow={false}>
                    <EuiLink>Log out</EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  )
}
