import { useLocation, useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { exchangeToken } from './api'
import { useStoredState, useStoredToken } from './localStorage'

export const useOAuthCallback = () => {
  const { storeValues } = useStoredToken()
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [error, setError] = useState()
  const { storedState } = useStoredState()
  const [params] = useSearchParams()

  const code = params.get('code')
  const state = params.get('state')
  const isStateValid = state && storedState && state === storedState

  useEffect(() => {
    if (!state || !storedState || storedState !== state) {
      return
    }
    if (isLoading || isSuccessful || isFailed) {
      return
    }
    setIsLoading(true)
    ;(async () => {
      try {
        const successToken = await exchangeToken(code)
        storeValues(successToken.access_token, new Date().getMilliseconds() + successToken.expires_in)
        setIsLoading(false)
        setIsSuccessful(true)
      } catch (e) {
        setIsLoading(false)
        setIsSuccessful(false)
        setIsFailed(true)
        setError(e)
      }
    })()
  }, [code, state, storedState])

  return {
    isStateValid,
    isLoading,
    isSuccessful,
    isFailed,
    error
  }
}
