import React from 'react'
import '@elastic/eui/dist/eui_theme_dark.css'
import { EuiProvider, EuiThemeProvider } from '@elastic/eui'
import { Outlet } from 'react-router-dom'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

export const AppLayout = () => {
  const themeOverrides = {
    colors: {
      DARK: {
        primary: 'rgb(102, 107, 226)',
        primaryText: 'rgb(102, 107, 226)',
        success: 'rgb(64,171,111)',
        successText: 'rgb(64,171,111)',
        warning: '#F2C94C',
        warningText: '#F2C94C',
        danger: 'rgb(235, 87, 87)',
        dangerText: 'rgb(235, 87, 87)',
        text: 'rgb(238, 239, 252)',
        subduedText: 'rgb(133, 134, 153)',
        body: 'rgb(25, 26, 35)'
      }
    }
  }
  //
  return (
    <EuiProvider colorMode="dark">
      <EuiThemeProvider modify={themeOverrides}>
        <Wrapper>
          <Outlet />
        </Wrapper>
      </EuiThemeProvider>
    </EuiProvider>
  )
}
