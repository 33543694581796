import { Route, Routes } from 'react-router-dom'
import { AppLayout } from './layouts/app-layout'
import { LoginRootPage } from './pages/login/root-page'
import { AnalyticsPage } from './pages/analytics'
import { CallbackPage } from './pages/login/callback'
import { AuthRequired } from './features/authentication'
import { AnalyticsLayout } from './layouts/analytics-layout'
import { TeamSelectionPage } from './pages/team-selection'

function App() {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="/login" element={<LoginRootPage />} />
        <Route path="/login/callback" element={<CallbackPage />} />
        <Route
          element={
            <AuthRequired>
              <AnalyticsLayout />
            </AuthRequired>
          }
        >
          <Route path="/" element={<AnalyticsPage />} />
          <Route path="/teams" element={<TeamSelectionPage />} />
          <Route path="/team/:teamKey" element={<AnalyticsPage />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App
