import React from 'react'
import { EuiPage, EuiPageBody, EuiPageTemplate } from '@elastic/eui'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Header } from './header'
import { TeamSelectionSidebar } from '../../pages/team-selection/page-sidebar'

export const AnalyticsLayout = () => {
  return (
    <EuiPageTemplate>
      <Routes>
        <Route path="/teams" element={<TeamSelectionSidebar />} />
      </Routes>
      <EuiPage>
        <EuiPageBody paddingSize="none" panelled>
          <Header />
          <Outlet />
        </EuiPageBody>
      </EuiPage>
    </EuiPageTemplate>
  )
}
