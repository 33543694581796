import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LinearProvider } from '../linear'
import { useStoredToken } from './localStorage'

export const AuthRequired = ({ children }) => {
  const { storedToken } = useStoredToken()
  const navigate = useNavigate()

  useEffect(() => {
    if (!storedToken) {
      navigate('/login')
    }
  }, [storedToken])

  if (!storedToken) {
    return <div>loading</div>
  }

  return <LinearProvider>{children}</LinearProvider>
}
