import { gql, useQuery } from '@apollo/client'
import { useUserTeams } from './useUserTeams.tsx'

interface Cycle {
  id: string
  name: string
  number: number
  progress: number
  startsAt: string
  endsAt: string
  updatedAt: string
  scopeHistory: number[]
  completedIssueCountHistory: number[]
  completedScopeHistory: number[]
}

interface QueryData {
  cycle: Cycle
}

interface QueryVariables {
  cycleId: number
}

const GET_CYCLE = gql`
  query ExampleQuery($cycleId: String!) {
    cycle(id: $cycleId) {
      name
      number
      progress
      scopeHistory
      completedIssueCountHistory
      completedScopeHistory
      startsAt
      id
      endsAt
      updatedAt
    }
  }
`

export const useCycle = cycleId => {
  const { loading, error, data } = useQuery<QueryData, QueryVariables>(GET_CYCLE, {
    skip: !cycleId,
    variables: { cycleId }
  })

  return { loading, error, cycle: data?.cycle || {} }
}

export const useTeamActiveCycle = teamKey => {
  const { teams } = useUserTeams()
  const selectedTeam = teams.find(team => team.key === teamKey)
  return useCycle(selectedTeam?.activeCycle?.id)
}
