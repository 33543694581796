import { gql, useQuery } from '@apollo/client'

interface Team {
  icon: string
  id: string
  key: string
  name: string
  color: string
  activeCycle: {
    id: string
  }
}

interface QueryData {
  viewer: {
    teams: { nodes: Team[] }
  }
}

const GET_TEAMS = gql`
  query GET_TEAMS {
    viewer {
      teams {
        nodes {
          icon
          id
          key
          name
          color
          activeCycle {
            id
          }
        }
      }
    }
  }
`

export const useUserTeams = () => {
  const { loading, error, data } = useQuery<QueryData>(GET_TEAMS)
  return { loading, error, teams: data?.viewer?.teams.nodes || [] }
}

export const useUserTeam = teamKey => {
  const { teams, loading, error } = useUserTeams()

  return { loading, error, team: teams.find(team => team.key === teamKey) }
}
