import React from 'react'

import { EuiPageTemplate } from '@elastic/eui'
import { useCycle, useUser, useUserTeams } from '../../features/linear'
import { useParams } from 'react-router-dom'
import { CycleBurdown } from './graphs/cycle-burdown'

export const AnalyticsPage = () => {
  const { currentUser } = useUser()
  const { teams } = useUserTeams()
  const { teamKey } = useParams()
  const selectedTeam = teams.find(team => team.key === teamKey)
  const { cycle } = useCycle(selectedTeam?.activeCycle?.id)
  return (
    <EuiPageTemplate.Section>
      AnalyticsPage - User name: {currentUser.name}- Team: {selectedTeam?.name}- Cycle: {cycle?.number} {cycle?.name}
      {cycle && <CycleBurdown cycle={cycle} />}
    </EuiPageTemplate.Section>
  )
}
