import React from 'react'
import { Link, Navigate } from 'react-router-dom'
import { EuiLoadingSpinner, EuiCallOut } from '@elastic/eui'
import styled from '@emotion/styled'
import { useOAuthCallback } from '../../../features/authentication'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CallbackPage = () => {
  const { isStateValid, isLoading, isSuccessful, isFailed } = useOAuthCallback()
  return (
    <Wrapper>
      {JSON.stringify({ isStateValid, isLoading, isSuccessful, isFailed })}
      {isStateValid && isLoading && <EuiLoadingSpinner size="xxl" />}
      {!isStateValid && (
        <EuiCallOut title="Authorization failed" color="danger" iconType="alert">
          <p>
            The returned state and the stored state are different.
            <br />
            <Link to="/login">Back to the login page</Link>
          </p>
        </EuiCallOut>
      )}
      {!isLoading && isFailed && (
        <EuiCallOut title="Authorization failed" color="danger" iconType="alert">
          <p>
            We were unable to confirm the authentication with Linear.
            <br />
            <Link to="/login">Back to the login page</Link>
          </p>
        </EuiCallOut>
      )}
      {!isLoading && isSuccessful && <Navigate to="../../" replace />}
    </Wrapper>
  )
}
