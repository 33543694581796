import { useEffect } from 'react'

export const getStoredAccessToken = () => window.localStorage.getItem('ACCESS_TOKEN')

export const useStoredState = state => {
  useEffect(() => {
    if (state) {
      window.localStorage.setItem('OAUTH_STATE', state)
    }
  }, [state])

  return {
    storedState: window.localStorage.getItem('OAUTH_STATE'),
    clear: () => window.localStorage.removeItem('OAUTH_STATE')
  }
}

export const useStoredToken = ({ accessToken, expiresIn } = {}) => {
  useEffect(() => {
    if (accessToken) {
      window.localStorage.setItem('ACCESS_TOKEN', accessToken)
    }
  }, [accessToken])

  useEffect(() => {
    if (expiresIn) {
      window.localStorage.setItem('TOKEN_EXPIRES_IN', expiresIn)
    }
  }, [expiresIn])

  return {
    storedToken: getStoredAccessToken(),
    storedExpiresIn: window.localStorage.getItem('TOKEN_EXPIRES_IN'),
    storeValues: (accessToken, expiresIn) => {
      window.localStorage.setItem('ACCESS_TOKEN', accessToken)
      window.localStorage.setItem('TOKEN_EXPIRES_IN', expiresIn)
    },
    clear: () => {
      window.localStorage.removeItem('ACCESS_TOKEN')
      window.localStorage.removeItem('TOKEN_EXPIRES_IN')
    }
  }
}
