import React from 'react'
import { useUserTeams } from '../../features/linear'
import { EuiCollapsibleNav, EuiListGroup, EuiListGroupItem, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'
import { NavLink as BaseNavLink } from 'react-router-dom'

export const TeamSelectionSidebar = () => {
  const { teams } = useUserTeams()
  return (
    <EuiCollapsibleNav isOpen={true} isDocked={false}>
      <div style={{ padding: 16 }}>
        <EuiTitle>
          <h1>Pick a team</h1>
        </EuiTitle>

        <EuiSpacer />
        <EuiText size="s" color="subdued">
          <p>Pick a team below to start looking at graphs</p>
        </EuiText>
        <EuiSpacer />
        <EuiListGroup flush color="primary">
          {teams.map(team => (
            <NavLink to={`/team/${team.key}/`} label={team.name} />
          ))}
        </EuiListGroup>
      </div>
    </EuiCollapsibleNav>
  )
}

const NavLink = React.forwardRef(({ activeClassName, activeStyle, ...props }, ref) => {
  return (
    <BaseNavLink to={props.to}>
      <EuiListGroupItem ref={ref} href="#" {...props} />
    </BaseNavLink>
  )
})
