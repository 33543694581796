export const CLIENT_ID = process.env.REACT_APP_LINEAR_OAUTH_CLIENT_ID
export const CLIENT_SECRET = process.env.REACT_APP_LINEAR_OAUTH_CLIENT_SECRET
export const REDIRECT_URI =
  process.env.NODE_ENV === 'production'
    ? window.location.host + '/login/callback'
    : 'http://' + window.location.host + '/login/callback'

export const LINEAR_AUTH_URL = 'https://linear.app/oauth/authorize'
export const LINEAR_TOKEN_URL = 'https://api.linear.app/oauth/token'
export const LINEAR_REVOKE_TOKEN_URL = 'https://api.linear.app/oauth/revoke'
