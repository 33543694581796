import React from 'react'
import { EuiLoadingContent, EuiPageTemplate } from '@elastic/eui'

export const TeamSelectionPage = () => {
  return (
    <EuiPageTemplate.Section>
      <EuiLoadingContent lines={6} />
    </EuiPageTemplate.Section>
  )
}
